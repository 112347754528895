/* 复选框类型表单项组件 */
<template>
  <el-form-item :label="itemData.label" v-bind="[itemData]" :prop="itemKey">
    <el-checkbox-group v-model="itemVal" v-bind="[itemData]" @change="change">
      <template v-if="itemData.style=='button'">
        <el-checkbox-button v-for="checkItem in itemData.children" :key="checkItem.value" :label="checkItem.value" :disabled="checkItem.disabled">
          {{checkItem.label}}
        </el-checkbox-button>
      </template>
      <template v-else>
        <el-checkbox v-for="checkItem in itemData.children" :key="checkItem.value" :label="checkItem.value" :disabled="checkItem.disabled" :border="checkItem.border">
          {{checkItem.label}}
        </el-checkbox>
      </template>
    </el-checkbox-group>
  </el-form-item>
</template>

<script>

export default {
  props: {

    // 表单项配置
    itemData: {
      type: Object,
      default: () => {
        return {}
      }
    },

    // 表单键值
    itemKey: String,

    // 父组件双向绑定的model值
    value: {
      type: Array
    },

    // 当前所在表单组配置项,用于事件函数的参数
    groupData: Object,

    // 表单配置项,用于事件函数的参数
    formData: Array,

    // 表单数据,用于事件函数的参数
    form: Object
  },
  model: {
    // 与父组件进行双向绑定
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      itemVal: this.value
    }
  },
  watch: {
    value() {
      this.itemVal = this.value
    }
  },
  methods: {
    change(val) {
      if (typeof (this.itemData.changeEvent) === 'function') {
        this.itemData.changeEvent(val, this.form, this.groupData, this.formData)
      }
      this.$emit('change', this.itemVal)
    }
  }
}

</script>
